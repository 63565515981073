import { ParcelListType, type ModuleConfigPartial, LayerListPosition } from '@maximap-base/lib/models';
import { FertilizerPlanTypeIcon, HomogenousDosePlanIcon, SelfZoneDosePlanIcon, ZoneStrengthDosePlanIcon } from '@maximap-base/lib/modules/plan';
import { PlanFileType } from '@maximap-base/lib/modules/plan/enums/plan-file-type.enum';
import { DosePlan, PlanType } from '@maximap-base/lib/modules/plan/models/plan.model';
import { markRaw } from 'vue';

export const getAppConfig = {
  parcel: {
    needParcelGroups: false,
    needGeoLayerUpload: true,
    listType: ParcelListType.CARDS,
    layerListPosition: LayerListPosition.RIGHT,
    needParcelLayerEditButton: false,
    needParcelSubLayerButton: false
  },
  map: {
    mapSourceURL: '',
    addedLayersNavLocation: 'left',
    layersNavLocation: 'right',
    numberOfMaps: 1,
    needSettings: false,
    needAutoMapChange: false
  },
  fertilizer: {
    needFilters: false,
    needAddNew: false,
    needHeader: false
  },
  plan: {
    needExpertAdvice: false,
    needFileUpload: false,
    needSidenav: true,
    needZoneDetails: false,
    needDetailsLayerChooser: false,
    needDetailsZoom: false,
    fertilizersRouteName: 'fertilizers',
    planChooserType: 'select',
    planTypeOptions: [
      {
        planType: PlanType.FERTILIZER_PLAN,
        title: 'plan.planType.fertilizerPlan',
        icon: markRaw(FertilizerPlanTypeIcon)
      }
    ],
    doseTypeOptions: [
      {
        doseType: DosePlan.HOMOGENEOUS,
        title: 'plan.dosePlan.homogeneous',
        icon: markRaw(HomogenousDosePlanIcon)
      },
      {
        doseType: DosePlan.ZONE_STRENGTH,
        title: 'plan.dosePlan.zoneStrength',
        icon: markRaw(ZoneStrengthDosePlanIcon)
      },
      {
        doseType: DosePlan.SELF_ZONE,
        title: 'plan.dosePlan.selfZone',
        icon: markRaw(SelfZoneDosePlanIcon)
      }
    ],
    availableDownloadFormats: [PlanFileType.SHP, PlanFileType.TRIMBLE_CFX, PlanFileType.TRIMBLE_GFX]
  }
} as ModuleConfigPartial;
