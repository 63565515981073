import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { createVuetify } from '@common/lib/modules/vuetify';
import { createRouter } from '@common/lib/modules/router';
import { createI18n } from '@common/lib/modules/i18n';
import themes from './app/themes';
import routes from './app/routes';
import messages from './i18n';
import OpenLayersMap from 'vue3-openlayers';
import { initForms, useModulesConfigStore } from '@maximap-base/index';

import './assets/scss/styles.scss';
import { createAuthGuard, setErrorHandlers } from '@common/index';
import { getAppConfig } from './modules.config';

import { FormDefaultAttributes } from '@base/index';

FormDefaultAttributes.setFormDefaultAttributes({
  density: 'compact',
  color: 'primary',
  variant: 'solo'
});

const app = createApp(App);
setErrorHandlers(app);

const pinia = createPinia();
const router = createRouter(routes);
const i18n = createI18n('hu', messages);
const vuetify = createVuetify(i18n, themes, {
  VTextField: {
    variant: 'solo',
    density: 'compact',
    color: 'primary'
  },
  VSelect: {
    variant: 'solo',
    density: 'compact',
    color: 'primary'
  }
});

initForms();

app.use(pinia);

const moduleConfigStore = useModulesConfigStore();
moduleConfigStore.setModuleConfig(getAppConfig);

createAuthGuard(router, 'login');

app.use(router);
app.use(i18n);
app.use(vuetify);
app.use(OpenLayersMap);
app.mount('#app');
